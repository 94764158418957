import {Alert, Col, OverlayTrigger, Popover, Row, Spinner} from "react-bootstrap";
import {
    Button,
    ButtonGroup,
    Card,
    Dialog,
    DialogBody,
    DialogFooter,
    EditableText,
    Elevation, FormGroup,
    InputGroup, NumericInput
} from "@blueprintjs/core";
import {lastSeenTimeColor} from "../../utils/deviceHealthColors";
import Moment from "react-moment";
import RssiIndicator from "../RssiIndicator";
import {ArrowDown, ArrowUp, ExclamationTriangleFill, FileText, Geo, Pencil} from "react-bootstrap-icons";
import BatteryIndicator from "../BatteryIndicator";
import PropTypes from "prop-types";
import React, {useContext, useState} from "react";
import {SelectedOrganizationContext} from "../../context/SelectedOrganizationContextProvider";
import {LinkContainer} from "react-router-bootstrap";

import LocationDialog from "./LocationDialog";
import AppParamDialog from "./AppParamDialog";
import { getSensorParamDetails } from "../../api";
import {useQuery} from "@tanstack/react-query";
import { useMemo } from "react";

export function SensorRow({sensor, buttons, enableBatteryWarning, sensorMutation}) {

    const [ showAppParamDialog, setShowAppParamDialog ] = useState(false);
    const [prevConfirmedSettings, setPrevConfirmedSettings] = useState({});
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const batteryPopover = <Popover id="popover-low-battery">
        <Popover.Header><strong>Warning: Low Battery</strong></Popover.Header>
        <Popover.Body>
            <span>
                Sensor may power down if battery level falls below 50% while commissioning.
            </span>
        </Popover.Body>
    </Popover>;

    const appParamDetailsQuery = useQuery({
        queryKey: [`appParamDetails`, sensor.id],
        queryFn: (arg) => {
            return getSensorParamDetails(sensor.id, sensor.json.organizationId);
        },
        enabled: sensor.id !== null,
        refetchOnWindowFocus: false,
        refetchInterval: 5000
    });

    const initialValues = useMemo(
        () => {
            if(appParamDetailsQuery.data !== undefined) {
                if (appParamDetailsQuery.data.sensor.latestSensorParamVersion !== null) {
                    return appParamDetailsQuery.data.sensor.latestSensorParamVersion.desiredValues;
                }
            }
            return undefined;
        },
        [appParamDetailsQuery.data]
    );

    const confirmedValues = useMemo(
        () => {
            if(appParamDetailsQuery.data !== undefined) {
                if (appParamDetailsQuery.data.sensor.latestSensorParamVersion !== null
                    && appParamDetailsQuery.data.sensor.latestSensorParamVersion.deliveredAt === null
                    && (Object.keys(prevConfirmedSettings).length > 0)){
                    return prevConfirmedSettings
                }
                else if (appParamDetailsQuery.data.sensor.latestSensorParamVersion !== null && appParamDetailsQuery.data.sensor.latestSensorParamVersion.deliveredAt !== null) {
                    setPrevConfirmedSettings(appParamDetailsQuery.data.sensor.latestSensorParamVersion.computedValues);
                    return appParamDetailsQuery.data.sensor.latestSensorParamVersion.computedValues;
                }
            }
            return undefined;
        },
        [appParamDetailsQuery.data]
    );

    return (
        <Card className="mb-2" elevation={Elevation.TWO}>
        <Row className="align-items-center">
            <Col md={{offset: 0, span: 2}} xs={{span: 6}}>
                <SensorLabel sensor={sensor} sensorMutation={sensorMutation} />
            </Col>

            <Col md={{offset: 0, span: 2}} xs={{span: 6}}>
                <div className="d-flex flex-column">
                    <div className="text-muted small"> Last Seen </div>
                    <div className="setup-row-time" style={{color: lastSeenTimeColor(sensor)}}>
                        {
                            sensor.json.lastSeen !== undefined
                            ? <Moment format="YYYY-MM-DD HH:mm:ss">{sensor.lastSeen}</Moment>
                            : "Not Available"
                        }
                    </div>
                </div>
            </Col>

            <Col md={{span: 2}} xs={{span: 4}}>
                <span className={"sensor-rssi-indicator"}>
                    <RssiIndicator val={sensor.status.rssiUpCategory} /><span className="arrow"><ArrowUp /></span>
                </span>
                {(sensor.status.rssiUp !== null && sensor.status.rssiUp !== 0) ? <span>{sensor.status.rssiUp} dBm</span> : <span> &ndash; &ndash;</span>}
            </Col>

            <Col md={{span: 2}} xs={{span: 4}}>
                <span className={"sensor-rssi-indicator"}>
                    <RssiIndicator val={sensor.status.rssiDownCategory} /><span className="arrow"><ArrowDown /></span>
                </span>
                {(sensor.status.rssiDown !== null && sensor.status.rssiDown !== 0) ? <span>{sensor.status.rssiDown} dBm</span> : <span> &ndash; &ndash;</span>}
            </Col>

            <Col md={{span: 1}} xs={{span: 4}}>

                <span className={"sensor-battery-indicator"}>
                    <BatteryIndicator val={sensor.status.batteryCategory} /> <span className="arrow"></span>
                </span>
                {sensor.status.battery !== null && <span>{sensor.status.battery}%</span>}
            </Col>

            <Col md={{span: 3}} xs={{span: 12}} className="d-flex flex-row justify-content-evenly pt-3">
                 {
                     enableBatteryWarning && sensor.status.battery < 50 &&
                     <OverlayTrigger overlay={batteryPopover} trigger="click" placement="top">
                         <Button intent={"warning"}><ExclamationTriangleFill /></Button>
                     </OverlayTrigger>
                }

                <Button intent="none" onClick={() => setShowAppParamDialog(true)}>Settings</Button>

                <Button intent={ sensor.manualLocation ? "primary" : ""} onClick={() => setIsDialogOpen(true)}>
                    <Geo />
                </Button>

                { isDialogOpen && <LocationDialog
                    device={sensor}
                    deviceMutation={sensorMutation}
                    isDialogOpen={isDialogOpen}
                    closeDialog={() => setIsDialogOpen(false)}
                /> }

                {buttons}

            </Col>
        </Row>
            {!sensor.stateMatch && <Alert className="mt-3 d-flex flex-row align-items-center justify-content-center" variant="warning">
                <Spinner /> <div className={"ms-3"}>{sensor.stateTransitionMessage}</div>
            </Alert> }
        <div>
            {
                initialValues !== undefined &&
                    <AppParamDialog
                        key={JSON.stringify(initialValues)}
                        initialSettings={initialValues}
                        isDialogOpen={showAppParamDialog}
                        closeDialog={() => setShowAppParamDialog(false)}
                        sensor={sensor}
                        sensorMutation={sensorMutation}
                        confirmedSettings={confirmedValues}
                    />
            }
        </div>
        </Card>
        );
}

SensorRow.propTypes = {
    sensor: PropTypes.object,
    buttons: PropTypes.element,
    enableBatteryWarning: PropTypes.bool,
    sensorMutation: PropTypes.object
};



function SensorLabel({sensor, sensorMutation}) {

    const {getOrgPageUrl} = useContext(SelectedOrganizationContext);
    const [editable, setEditable] = useState(() => sensor.name !== null);


    if (!editable) {
        return <div className="d-flex flex-column text-nowrap">
            <div className="text-muted small d-flex flex-row align-items-center">
                <div className="flex-grow-1">Device ID</div>
                <ButtonGroup minimal={true}>
                    <Button minimal={true} onClick={() => setEditable(true)}> <Pencil /> </Button>
                    <LinkContainer target={"_blank"} to={{pathname: getOrgPageUrl(`log/0`), search:`sensorId=${sensor.id}`}}>
                        <Button minimal={true} intent="primary"><FileText/></Button>
                    </LinkContainer>
                </ButtonGroup>
            </div>
            <div className="setup-row-text ">
                <span className="me-2">{sensor.deviceId}</span>
            </div>
        </div>;
    } else {
        return <div className="d-flex flex-column">
            <div className="text-muted small d-flex flex-row align-items-center">
                <div className="flex-grow-1">Device Name</div>
                <ButtonGroup minimal={true}>
                    {/*<Button minimal={true} onClick={() => setEditable(true)}> <Pencil /> </Button>*/}
                    <LinkContainer target={"_blank"} to={{pathname: getOrgPageUrl(`log/0`), search:`sensorId=${sensor.id}`}}>
                        <Button minimal={true} intent="primary"><FileText/></Button>
                    </LinkContainer>
                </ButtonGroup>
            </div>
            <div className="setup-row-text">
                <span className="me-2">
                    <EditableText
                        onConfirm={(value) => sensorMutation.mutate(
                            {deviceId: sensor.id, action: "update", name: value}
                        )}
                        defaultValue={sensor.name}
                    />
                </span>
            </div>
            <div className="text-muted"><small> { sensor.deviceId } </small></div>
        </div>;
    }
}

SensorLabel.propTypes = {
    sensor: PropTypes.object,
    sensorMutation: PropTypes.object
};
