import React, {useState, useEffect, useMemo, useCallback, useContext} from "react";
import Moment from "react-moment";
import {archiveSensor, getApiConfig, getSensors, getWeatherGrid, getWeatherTypes, Sensor, getSensorParamDetails} from "../api";
import PropTypes from "prop-types";
import {Form, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "react-bootstrap";
import {Button} from "@blueprintjs/core";
import {Link} from "react-router-dom";
import { Gear } from "react-bootstrap-icons";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {Switch} from "@blueprintjs/core";
import axios from "axios";
import {DeviceListContext} from "../context/DeviceListContextProvider";
import AppParamDialog from "./setup/AppParamDialog";
import { isFenceMode } from "./EventView";

// function to return the Gateway Device Id from filtering the Gateway Id (database index id)
function getGatewayDeviceId(gateways, gatewayId){
    let gatewayDeviceId = gateways.filter(gateway => gateway.id === gatewayId)
    return gatewayDeviceId[0]?.json.gatewayId
}

// Get access to request restart
// => can only request restart once every 5 minutes
function allowResetRequested(restartTimestamp){
    if(!restartTimestamp){
        return true
    }

    // Time difference for each restart request => 60 Minutes each restart request
    const timeThreshold = 60 * 60000

    let timeDifference = Date.now() - restartTimestamp;
    if (timeDifference >= timeThreshold){
        return true
    } else {
        return false
    }
}

function SensorStatusView(props){
    const {selectedSensor, organization, weatherGrids, gateways} = props;

    const [ showAppParamDialog, setShowAppParamDialog ] = useState(false);

    const [restartRequested, setRestartRequested] = useState(!allowResetRequested(Date.parse(selectedSensor?.json?.requestedRestartTimestamp + "Z")));
    const [restartRequestedTimestamp, setRestartRequestedTimestamp] = useState(Date.parse(selectedSensor?.json?.requestedRestartTimestamp + "Z"))

    useEffect(() => {
        // Update state whenever selectedSensor changes
        setRestartRequested(!allowResetRequested(Date.parse(selectedSensor?.json?.requestedRestartTimestamp + "Z")));
        setRestartRequestedTimestamp(Date.parse(selectedSensor?.json?.requestedRestartTimestamp + "Z"));
      }, [selectedSensor, allowResetRequested(Date.parse(selectedSensor?.json?.requestedRestartTimestamp + "Z"))]);

    const [prevConfirmedSettings, setPrevConfirmedSettings] = useState({});

    // We save a local copy of the sensor from mutation responses.

    const {sensorMutation} = useContext(DeviceListContext);

    // Data queries
    const weatherTypesQuery = useQuery({
        queryKey: [`weatherTypes`],
        queryFn: (arg) => {
            return getWeatherTypes();
        }
    });

    // Queries
    const weatherGridQuery = useQuery({
        queryKey: [`currentWeatherGrid`, selectedSensor.nwsGridId],
        queryFn: (arg) => {
            return getWeatherGrid(selectedSensor.nwsGridId, true);
        },
        refetchOnWindowFocus: true,
        enabled: selectedSensor.nwsGridId !== null

    });

    const appParamDetailsQuery = useQuery({
        queryKey: [`appParamDetails`, selectedSensor.id],
        queryFn: (arg) => {
            return getSensorParamDetails(selectedSensor.id, organization.id);
        },
        enabled: selectedSensor.id !== null,
        refetchOnWindowFocus: false,
        refetchInterval: 5000
    });


    const weatherDataAvailable = (
        weatherGridQuery.status === 'success' && weatherGridQuery.data !== undefined &&
        weatherTypesQuery.status === 'success' && weatherTypesQuery.data !== undefined
    );

    const weatherGrid = useMemo(
        () => weatherGrids.find(grid => grid.id === selectedSensor.nwsGridId),
        [selectedSensor, weatherGrids]
    );

    const initialValues = useMemo(
        () => {
            if(appParamDetailsQuery.data !== undefined) {
                if (appParamDetailsQuery.data.sensor.latestSensorParamVersion !== null) {
                    return appParamDetailsQuery.data.sensor.latestSensorParamVersion.desiredValues;
                }
            }
            return undefined;
        },
        [appParamDetailsQuery.data]
    );

    const confirmedValues = useMemo(
        () => {
            if(appParamDetailsQuery.data !== undefined) {
                if (appParamDetailsQuery.data.sensor.latestSensorParamVersion !== null
                    && appParamDetailsQuery.data.sensor.latestSensorParamVersion.deliveredAt === null
                    && (Object.keys(prevConfirmedSettings).length > 0)){
                    return prevConfirmedSettings
                }
                else if (appParamDetailsQuery.data.sensor.latestSensorParamVersion !== null && appParamDetailsQuery.data.sensor.latestSensorParamVersion.deliveredAt !== null) {
                    setPrevConfirmedSettings(appParamDetailsQuery.data.sensor.latestSensorParamVersion.computedValues);
                    return appParamDetailsQuery.data.sensor.latestSensorParamVersion.computedValues;
                }
            }
            return undefined;
        },
        [appParamDetailsQuery.data]
    );

    return (
        <div>
            { selectedSensor.name && <p><b>Sensor Name</b>: {selectedSensor.name}</p> }
            <p><b>Sensor ID</b>: {selectedSensor.deviceId}</p>
            <p><b>Last seen</b>: <Moment fromNow>{selectedSensor.lastSeen}</Moment> </p>
            <p><b>Node Type</b>: {confirmedValues?.Node_Type === 1 ? 'Repeater Node' : 'Sensor Node'}</p>
            <p><b>Via</b>: Gateway {getGatewayDeviceId(gateways, selectedSensor.status?.json.gatewayId)} </p>
            <p><b>Battery</b>: {selectedSensor.status && selectedSensor.status.battery !== null ? `${selectedSensor.status.battery}%` : "unknown"} </p>
            {organization.hasRole("admin-dev") ?
                <p><b>System Status</b>: {selectedSensor.status && selectedSensor.status.systemStatus !== null ? selectedSensor.status.systemStatus : "unknown"} </p>
                : null
            }
            {/* Display Sensor is on Fence Mode */}
            {isFenceMode(selectedSensor.status.systemStatus) ? 
            <p><b>Mode</b>: Fence Mode</p>
            : null  
            }
            
            <p><b>RSSI Up</b>: {selectedSensor.status && selectedSensor.status.rssiUp !== null ? `${selectedSensor.status.rssiUp} dBm` : "unknown"}</p>
            <p><b>RSSI Down</b>: {selectedSensor.status && selectedSensor.status.rssiDown !== null ? `${selectedSensor.status.rssiDown} dBm` : "unknown"}</p>
            {   weatherDataAvailable ?
                <p><b>Temperature</b>: {Math.round(weatherGridQuery.data.getTemperatureFahrenheit(weatherTypesQuery.data), 2)}&deg;F</p>
                : null
            }
            {
                weatherGrid !== undefined ? <p> Weather Region: {weatherGrid.name} </p> : null
            }
            <div>
                {
                    initialValues !== undefined &&
                        <AppParamDialog
                            key={JSON.stringify(initialValues)}
                            initialSettings={initialValues}
                            isDialogOpen={showAppParamDialog}
                            closeDialog={() => setShowAppParamDialog(false)}
                            sensor={selectedSensor}
                            sensorMutation={sensorMutation}
                            confirmedSettings={confirmedValues}
                        />
                }
            </div>

            <div className="status-button-bar">
                {
                    organization.hasRole("admin")
                    ? <Button intent="none" onClick={() => setShowAppParamDialog(true)}>Settings</Button>
                    : null
                }
                {
                    organization.hasRole("admin") ? 
                    <Button 
                        intent={"danger"} 
                        disabled={restartRequested} 
                        onClick={() => {
                            sensorMutation.mutate({deviceId: selectedSensor.id, action: "update", restart: true});
                            setRestartRequested(true);
                        }}
                    >Restart</Button>
                    : null
                }
            </div>
            { restartRequested ? 
                    <div style={{textAlign:'right'}}> Restart Requested <Moment fromNow>{restartRequestedTimestamp}</Moment>.</div> 
            : null }
        </div>
    )
}

SensorStatusView.propTypes = {
    selectedSensor: PropTypes.object,
    organization: PropTypes.object,
    weatherGrids: PropTypes.array,
    gateways: PropTypes.object,
}

export default SensorStatusView