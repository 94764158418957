import React, {useState} from "react";
import {Button, Dialog, DialogBody, DialogFooter, FormGroup, NumericInput} from "@blueprintjs/core";
import PropTypes from "prop-types";

export default function LocationDialog({device, deviceMutation, isDialogOpen, closeDialog}) {

    const currentLocation = device.currentLocation;

    const [latitude, setLatitude] = useState(() => `${currentLocation?.latitude || 0}`);
    const [longitude, setLongitude] = useState(() => `${currentLocation?.longitude || 0}`);

    function save(){
        deviceMutation.mutate({
            deviceId: device.id,
            action: "update",
            latitude: parseFloat(latitude),
            longitude: parseFloat(longitude)
        });
        closeDialog();
    }

    function deleteLocation(){
        deviceMutation.mutate({
            deviceId: device.id,
            action: "update",
            deleteLocation: true
        });
        closeDialog();
    }

    return <Dialog title="Location" icon="info-sign" isOpen={isDialogOpen} onClose={() => closeDialog()}>
        <DialogBody>
            {
                !currentLocation ? 
                    <div>Location <b>Not Set</b></div> :
                        currentLocation?.manual
                        ? <div>Location Set <b>Manually</b></div>
                        : <div>Location Set by <b>GPS</b></div>
            }

            <FormGroup
                helperText="Latitude of the device"
                label="Latitude"
                labelFor="lat-input"
                labelInfo="(required)"
            >
                <NumericInput
                    id="lat-input"
                    placeholder=""
                    value={latitude}
                    onValueChange={(value, s) => setLatitude(s)}
                />
            </FormGroup>
            <FormGroup
                helperText="Longitude for the device"
                label="Longitude"
                labelFor="lon-input"
                labelInfo="(required)"
            >
                <NumericInput
                    id="lon-input"
                    placeholder=""
                    value={longitude}
                    onValueChange={(value, s) => setLongitude(s)}
                />
            </FormGroup>

        </DialogBody>
        <DialogFooter actions={<>
            <Button intent="none" text="Cancel" onClick={() => closeDialog()} />
            <Button intent="danger" text="Use GPS" onClick={() => deleteLocation()} />
            <Button intent="primary" text="Save" onClick={() => save()} />
            </>}
        />
    </Dialog>
}

LocationDialog.propTypes = {
    device: PropTypes.object,
    deviceMutation: PropTypes.object,
    isDialogOpen: PropTypes.bool,
    closeDialog: PropTypes.func
}