import React, {useContext, useEffect, useMemo, useState} from "react";
import Moment from "react-moment";
import {
    Sensor,
    archiveGateway,
    getWeatherTypes,
    getWeatherGrid,
    getSensorParamDetails,
    getGatewayParamDetails
} from "../api";
import PropTypes from "prop-types";
import {FiletypeCsv, CardText, Geo, Gear} from "react-bootstrap-icons";
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Form} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {Switch} from "@blueprintjs/core";
import {useMutation, useQuery} from '@tanstack/react-query'
import SelectedOrganizationContextProvider, {
    SelectedOrganizationContext
} from "../context/SelectedOrganizationContextProvider";
import LocationDialog from "./setup/LocationDialog";
import {DeviceListContext} from "../context/DeviceListContextProvider";
import {LinkContainer} from "react-router-bootstrap";
import AppParamDialog from "./setup/AppParamDialog";
import GatewayParamDialog from "./setup/GatewayParamDialog";

function GatewayStatusView(props){
    const {selectedDevice, organization, weatherGrids} = props;

    const navigate = useNavigate();

    const {getPageUrl} = useContext(SelectedOrganizationContext);

    const [ allowShutdown, setAllowShutdown ] = useState(selectedDevice?.json?.requestedShutdownTime == null || selectedDevice?.json?.requestedShutdownTime < selectedDevice?.json?.lastShutdownTime)

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const [ showAppParamDialog, setShowAppParamDialog ] = useState(false);

    const [prevConfirmedSettings, setPrevConfirmedSettings] = useState({});

    const {gatewayMutation} = useContext(DeviceListContext);

    useEffect(()=> {
        setAllowShutdown(selectedDevice?.json?.requestedShutdownTime == null || selectedDevice?.json?.requestedShutdownTime < selectedDevice?.json?.lastShutdownTime)
    }, [selectedDevice, selectedDevice?.json?.lastShutdownTime])

    const weatherGrid = useMemo(
        () => weatherGrids.find(grid => grid.id === selectedDevice.nwsGridId),
        [selectedDevice, weatherGrids]
    );

    // Data queries
    const weatherTypesQuery = useQuery({
        queryKey: [`weatherTypes`],
        queryFn: (arg) => {
            return getWeatherTypes();
        }
    });

    // Queries
    const weatherGridQuery = useQuery({
        queryKey: [`currentWeatherGrid`, selectedDevice.nwsGridId],
        queryFn: (arg) => {
            return getWeatherGrid(selectedDevice.nwsGridId, true);
        },
        refetchOnWindowFocus: true,
        enabled: selectedDevice.nwsGridId !== null

    });

    const appParamDetailsQuery = useQuery({
        queryKey: [`gatewayParamDetails`, selectedDevice.id],
        queryFn: (arg) => {
            return getGatewayParamDetails(selectedDevice.id, organization.id);
        },
        enabled: selectedDevice.id !== null
    });


    const weatherDataAvailable = (
        weatherGridQuery.status === 'success' && weatherGridQuery.data !== undefined &&
        weatherTypesQuery.status === 'success' && weatherTypesQuery.data !== undefined
    );

    const initialValues = useMemo(
        () => {
            if(appParamDetailsQuery.data !== undefined) {
                if (appParamDetailsQuery.data.sensor.latestGatewayParamVersion !== null) {
                    return appParamDetailsQuery.data.sensor.latestGatewayParamVersion.desiredValues;
                }
            }
            return undefined;
        },
        [appParamDetailsQuery.data]
    );

    const confirmedValues = useMemo(
        () => {
            if(appParamDetailsQuery.data !== undefined) {
                if (appParamDetailsQuery.data.sensor.latestGatewayParamVersion !== null 
                    && appParamDetailsQuery.data.sensor.latestGatewayParamVersion.deliveredAt === null 
                    && (Object.keys(prevConfirmedSettings).length > 0)){
                    return prevConfirmedSettings
                }
                else if (appParamDetailsQuery.data.sensor.latestGatewayParamVersion !== null && appParamDetailsQuery.data.sensor.latestGatewayParamVersion.deliveredAt !== null) {
                    setPrevConfirmedSettings(appParamDetailsQuery.data.sensor.latestGatewayParamVersion.computedValues);
                    return appParamDetailsQuery.data.sensor.latestGatewayParamVersion.computedValues;
                }
            }
            return undefined;
        },
        [appParamDetailsQuery.data]
    );

    return (
        <div>
            <p><b>Gateway ID:</b> {selectedDevice.deviceId}</p>
            <p><b>Last seen:</b> <Moment fromNow>{selectedDevice.lastSeen}</Moment></p>
            {selectedDevice?.status?.json?.batteryPercentage ? 
            <p><b>Battery:</b> {selectedDevice.status.json.batteryPercentage}%</p>    
            : null}
            {selectedDevice?.status?.json?.busVoltage ? 
            <p><b>Bus Voltage:</b> {selectedDevice.status.json.busVoltage}V</p>    
            : null}
            {selectedDevice?.status?.json?.sysVoltage ? 
            <p><b>System Voltage:</b> {selectedDevice.status.json.sysVoltage}V</p>    
            : null}
            {selectedDevice?.status?.json?.gatewayPowerStatus ? 
            <p><b>Power Status:</b> {selectedDevice.status.powerStatusLabel}</p>    
            : null}
            {selectedDevice?.status?.json?.lastShutdownTime ? 
            <p><b>Last Shutdown Time:</b> <Moment format="YYYY-MM-DD HH:mm:ss">{selectedDevice.status.json.lastShutdownTime + "Z"}</Moment></p>    
            : null}
            {weatherDataAvailable ?
                <p><b>Temperature:</b> {Math.round(weatherGridQuery.data.getTemperatureFahrenheit(weatherTypesQuery.data), 2)}&deg;F</p>
                : null
            }
            {weatherGrid !== undefined ? <p><b>Weather Region:</b> {weatherGrid.name} </p> : null}

            <div>
                {
                    initialValues !== undefined &&
                    <GatewayParamDialog
                        initialSettings={initialValues}
                        isDialogOpen={showAppParamDialog}
                        closeDialog={() => setShowAppParamDialog(false)}
                        gateway={selectedDevice}
                        gatewayMutation={gatewayMutation}
                        confirmedSettings={confirmedValues}
                    />
                }
            </div>
            <div className="status-button-bar">

                {/*// href={getPageUrl(`log/${selectedDevice.id}`)}*/}
                <Button
                    variant="secondary" size="sm"
                    onClick={() => navigate(getPageUrl(`log/${selectedDevice.id}`))}>
                    <CardText/> Logs
                </Button>

                {
                    organization.hasRole("admin")
                        ? <Button variant={"secondary"} onClick={() => setShowAppParamDialog(true)}>Settings</Button>
                        : null
                }
                {
                    organization.hasRole("admin")
                    && <Button size="sm" variant={selectedDevice.manualLocation ? "primary" : "secondary"}
                               onClick={() => setIsDialogOpen(true)}>
                        <Geo/>
                    </Button>
                }
                {isDialogOpen && <LocationDialog
                    device={selectedDevice}
                    deviceMutation={gatewayMutation}
                    isDialogOpen={isDialogOpen}
                    closeDialog={() => setIsDialogOpen(false)}
                />}
                {
                    organization.hasRole("admin")
                        ? <Button 
                            variant={"danger"} 
                            disabled = {!allowShutdown}
                            onClick={() => {
                                setAllowShutdown(false);
                                gatewayMutation.mutate({deviceId: selectedDevice.id, organizationId: organization.id, action: "update", shutdown: true})
                            }}>
                            Shutdown
                        </Button>
                        : null
                }
            </div>
            { !allowShutdown && selectedDevice?.json?.requestedShutdownTime ? 
                    <div style={{textAlign:'right'}}> Shutdown Requested <Moment fromNow>{selectedDevice?.json?.requestedShutdownTime+'Z'}</Moment>.</div> 
            : null }
        </div>
    );
}

GatewayStatusView.propTypes = {
    selectedDevice: PropTypes.object,
    organization: PropTypes.object,
    weatherGrids: PropTypes.array
}
export default GatewayStatusView