import {useQuery} from "@tanstack/react-query";
import {getSensors, getEvents, Sensor, getGateways, Gateway} from "../api";
import {useContext, useEffect, useMemo, useState} from "react";
import MapboxPlot from "../components/MapboxPlot";
import {Dropdown, Col, Row, Accordion, Button, Form, Alert} from "react-bootstrap";
import AlertBoxes from "../components/AlertBoxes";
import React from "react";
import EventView from "../components/EventView";
import SensorStatusView from "../components/SensorStatusView";
import {MapboxLayout, MapMode} from "../MapboxLayouts";
import PropTypes from "prop-types";
import {DateRangePicker} from "@blueprintjs/datetime";
import Moment from "react-moment";
import moment from "moment-timezone";
import {CaretDownFill} from 'react-bootstrap-icons';
import GatewayStatusView from "../components/GatewayStatusView";
import {Spinner} from "react-bootstrap";
import {useWindowDimensions} from "../utils/windowDimensions";
import DateRangeSelector from "../components/DateRangeSelector";
import {Switch} from "@blueprintjs/core";
import DeviceSelector from "../components/DeviceSelector";
import {DeviceListContext} from "../context/DeviceListContextProvider";
import {MapboxContext} from "../context/MapboxContextProvider";
import {SelectedDeviceContext} from "../context/SelectedDeviceContextProvider";
import {WeatherContext} from "../context/WeatherContextProvider";
import {AppConfigContext} from "../context/AppConfigContextProvider";
import {TimeframeContext} from "../context/TimeframeContextProvider";
import {SelectedOrganizationContext} from "../context/SelectedOrganizationContextProvider";
import ZoneConfigurationButton from "../components/ZoneConfigurationButton";

function filterEvents(query, sensorMap){
    if(query.status === 'success' && query.data !== undefined){
        const newAlerts = query.data.events;

        // filter new alerts for only those which have a sensor available.
        const alerts = newAlerts.filter(alert => sensorMap.get(alert.sensorId) !== undefined);
        alerts.forEach(alert => alert.sensor = sensorMap.get(alert.sensorId));
        return alerts;
    } else {
        return [];
    }
}


function Sensors(props) {

    const {timeframe, setCustomTimeframe: setCustomQueryTimeframe} = useContext(TimeframeContext);
    const {organization, zone} = useContext(SelectedOrganizationContext);
    const {sensorQuery, gatewayQuery, sensors, gateways} = useContext(DeviceListContext);
    const {mapboxLayout, mapboxMode:mapMode} = useContext(MapboxContext);
    const {selectedDevice, setSelectedDevice:onSelectDevice} = useContext(SelectedDeviceContext);
    const {allWeatherGrids: weatherGrids} = useContext(WeatherContext);
    const {config} = useContext(AppConfigContext);


    const [showDatePicker, setShowDatePicker] = useState(false);
    const [showGateways, setShowGateways] = useState(true);

    const {width: windowWidth, height: windowHeight} = useWindowDimensions();

    const alertQuery = useQuery({
        queryKey: [`alert-events`, organization.id, zone?.id],
        queryFn: (arg) => {
            return  getEvents(organization.id, 2, null, null, null, null, zone?.id);
        },
        refetchOnWindowFocus: true,
        refetchInterval: 5000


    });

    const [eventRowLimit, setEventRowLimit] = useState(1000);

    const eventQuery = useQuery({
        queryKey: [`events`, organization.id, timeframe.startTime, timeframe.endTime, zone?.id],
        queryFn: (arg) => {
            return getEvents(
                organization.id,
                eventRowLimit,
                null,
                timeframe.startTime,
                timeframe.endTime,
                [1, 2],
                zone?.id
            );
        },
        refetchOnWindowFocus: false,
        // Only enable for appropriate map modes.
        enabled: mapMode === MapMode.Heatmap || mapMode === MapMode.Animation
    });

    const eventDataAvailable = eventQuery.isSuccess && eventQuery.data !== undefined;


    // When mapMode changes, hide date picker.
    useEffect(
        () =>{
            setShowDatePicker(false);
        },
        [mapMode]
    )


    let alerts = [];
    let events = [];


    // Build sensor related stuff
    let sensorLabel = "Devices";
    let sensorDetail = (<div></div>);
    if(selectedDevice !== null){

        sensorLabel = `${selectedDevice.deviceType}: ${selectedDevice.deviceId}`;

        if(selectedDevice instanceof Sensor) {
            sensorDetail = (
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header> Sensor Status</Accordion.Header>
                        <Accordion.Body>
                            <SensorStatusView
                                selectedSensor={selectedDevice}
                                organization={organization}
                                weatherGrids={weatherGrids}
                                gateways={gatewayQuery.data.gateways}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header> Past Detections </Accordion.Header>
                        <Accordion.Body>
                            <EventView
                                organizationId={organization.id}
                                selectedSensor={selectedDevice}
                                gateways={gatewayQuery.data.gateways}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header> Debug Info </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <p>Sensor:</p>
                                <pre>{JSON.stringify(selectedDevice.json, null, 2)}</pre>
                                <p> Most recent status: </p>
                                <pre>{JSON.stringify(selectedDevice.status.json, null, 2)}</pre>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            );
        }
        if(selectedDevice instanceof Gateway) {
            sensorDetail = (
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header> Gateway Status</Accordion.Header>
                        <Accordion.Body>
                            <GatewayStatusView
                                selectedDevice={selectedDevice}
                                organization={organization}
                                weatherGrids={weatherGrids}
                            />
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                        <Accordion.Header> Debug Info </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <p>Gateway:</p>
                                <pre>{JSON.stringify(selectedDevice.json, null, 2)}</pre>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            );
        }
    }


    // build map

    const sensorMap = useMemo(
        () => new Map(sensors.map(sensor => [sensor.id, sensor])),
        [sensors]
    );



    // Build a map of sensors by sensor ID

    alerts = filterEvents(alertQuery, sensorMap);
    events = filterEvents(eventQuery, sensorMap);

    const updated = (<span> Updated : {moment(sensorQuery.data.updated).tz(moment.tz.guess()).format("h:mm:ss a z")} </span>);


    const eventRowLimitExceeded = (
        eventDataAvailable
        && (mapMode === MapMode.Heatmap || mapMode === MapMode.Animation)
        && eventQuery.data.events.length === eventRowLimit
    );

    return (
        <div>
            <AlertBoxes
                alerts={alerts}
                onSelectSensor={(sensor) => onSelectDevice(sensor)}
            />
            {
                eventRowLimitExceeded ?
                <Alert variant="warning">
                    Warning: Animation / Heatmap event limit exceeded: {eventRowLimit} events.
                    Retry the search with a shorter time period.
                </Alert>
                : null
            }
            <Row className="top-selector-row">
                <Col xs={12} lg={4} >
                    <DeviceSelector
                        sensors={sensors}
                        gateways={gateways}
                        selectedDevice={selectedDevice}
                        onSelectDevice={onSelectDevice}
                    />
                </Col>
                {(gateways.length > 0 && sensors.length > 0 && mapMode == MapMode.Normal) ? <Col xs={12} lg={4}>
                    <Form className="mt-2 mt-lg-0 text-lg-center">
                        <Switch
                            defaultChecked={showGateways}
                            onClick={() => setShowGateways(prev => !prev)}
                        > Show Gateways </Switch>
                    </Form>
                </Col>:<Col xs={12} lg={4}> </Col>}
                {mapMode == MapMode.Normal ?
                    <Col className="text-lg-end" xs={12} lg={4}>
                        { (organization.hasRole("admin") && zone !== undefined) ? 
                            <ZoneConfigurationButton /> 
                            : 
                            <>
                                {updated}
                                <Alert key="primary-alert" variant="primary" style={{textAlign:'center'}}>
                                    Please select a zone in your organization in order to manage your sensors.
                                </Alert>
                            </>
                        }
                    </Col>
                    : <Col className="text-lg-end" xs={12} lg={4} >
                        <Button onClick={() => setShowDatePicker(prev => !prev)} variant="light">
                            <Moment format="YYYY/MM/DD">{timeframe.startTime}</Moment> - <Moment format="YYYY/MM/DD">{timeframe.endTime}</Moment>
                            &nbsp;<CaretDownFill size={12} />
                        </Button>
                    </Col>
                }
            </Row>
            { showDatePicker ?
                <Row className="date-picker-row">
                    <Col md={{offset: 4, span: 8}}>
                        <DateRangeSelector
                            organization={organization}
                            timeframe={timeframe}
                            setCustomTimeframe={timeframe => {
                                setCustomQueryTimeframe(timeframe);
                                setShowDatePicker(false);
                            }}
                        />
                    </Col>
                </Row>
                : null
            }

            <Row>
                <Col lg={8}>
                    <div className="map-outer-container">
                    <MapboxPlot
                        sensors={sensors}
                        gateways={showGateways ? gateways : []}
                        selectedSensor={selectedDevice}
                        clickSensor={(sensor) => onSelectDevice(sensor)}
                        alerts={alerts}
                        events={events}
                        mapboxLayout={mapboxLayout}
                        mapMode={mapMode}
                        timeframe={timeframe}
                        config={config}
                    />
                    </div>
                </Col>
                <Col lg={4}>{sensorDetail}</Col>
            </Row>
        </div>
    )
}

Sensors.propTypes = {}

export default Sensors
