import {Button} from "@blueprintjs/core";
import PropTypes from "prop-types";
import React, { useState } from "react";
import {Form, Modal, ModalBody, ModalFooter, ModalHeader} from "react-bootstrap";

export function DeactivateButton({sensor, sensorMutation, disabled}){
    return <Button
        intent="danger"
        variant="secondary"
        size={"sm"}
        disabled={disabled}
        onClick={() => sensorMutation.mutate({deviceId: sensor.id, action: "update", commission: "DEACTIVATED"})}
    >
        Deactivate
    </Button>;
}

DeactivateButton.propTypes = {
    sensor: PropTypes.object,
    sensorMutation: PropTypes.object,
    disabled: PropTypes.bool
}


export function CommissionButton({sensor, sensorMutation, disabled}){
    return <Button
        variant="secondary"
        size={"sm"}
        disabled={disabled}
        onClick={() => sensorMutation.mutate({deviceId: sensor.id, action: "update", commission: "COMMISSIONED"})}
    >
        Commission
    </Button>;
}

CommissionButton.propTypes = {
    sensor: PropTypes.object,
    sensorMutation: PropTypes.object,
    disabled: PropTypes.bool
}

export function ReactivateButton({sensor, sensorMutation, disabled}){
    return <Button
        variant="secondary"
        size={"sm"}
        disabled={disabled}
        onClick={() => sensorMutation.mutate({deviceId: sensor.id, action: "update", commission: "UNCOMMISSIONED"})}
    >
        Reactivate
    </Button>;
}

ReactivateButton.propTypes = {
    sensor: PropTypes.object,
    sensorMutation: PropTypes.object,
    disabled: PropTypes.bool
}

export function RemoveFromZoneButton({sensor, zone, sensorMutation, disabled}){
    const [ showDialog, setShowDialog ] = useState(false)

    return (
        <>
            <Modal show={showDialog}>
                <Form onSubmit={event => {event.preventDefault(); sensorMutation.mutate({deviceId: sensor.id, action: "remove", zoneId: zone.id});}}>
                    <ModalHeader>Remove Sensor From Zone</ModalHeader>
                    <ModalBody>
                        <p>Removing a sensor from the zone will permanently remove it and reset all app parameters. Do you want to proceed?</p>
                    </ModalBody>

                    <ModalFooter>
                        <Button intent="none" onClick={() => setShowDialog(false)}>Cancel</Button>
                        <Button intent="danger" type="submit" style={{textAlign:'center', textWrap:'nowrap'}}>Reset and Remove</Button>
                    </ModalFooter>
                </Form>
            </Modal>
            <Button
                intent="danger"
                disabled={disabled}
                // onClick={() => sensorMutation.mutate({deviceId: sensor.id, action: "remove", zoneId: zone.id})}
                onClick={() => setShowDialog(true)}
                style={{textAlign:'center', textWrap:'nowrap'}}
            >
                Reset and Remove
            </Button>
        </>
    )
}

RemoveFromZoneButton.propTypes = {
    sensor: PropTypes.object,
    zone: PropTypes.object,
    sensorMutation: PropTypes.object,
    disabled: PropTypes.bool
}


export function BuildButtons({sensor, zone, sensorMutation, disabled:buttonsDisabled}){
    if (sensor.isCommissioned){
        return <DeactivateButton sensor={sensor} sensorMutation={sensorMutation} disabled={buttonsDisabled} />;

    }
    if (!sensor.isCommissioned && !sensor.isDeactivated){
        return <>
            <CommissionButton sensor={sensor} sensorMutation={sensorMutation} disabled={buttonsDisabled} />
            <DeactivateButton sensor={sensor} sensorMutation={sensorMutation} disabled={buttonsDisabled}/>
        </>;
    }
    if (sensor.isDeactivated){
        return <>
            <ReactivateButton sensor={sensor} sensorMutation={sensorMutation} disabled={buttonsDisabled} />
            <RemoveFromZoneButton sensor={sensor} zone={zone} sensorMutation={sensorMutation} disabled={buttonsDisabled} />
        </>;
    }
}


BuildButtons.propTypes = {
    sensor: PropTypes.object,
    zone: PropTypes.object,
    sensorMutation: PropTypes.object,
    disabled: PropTypes.bool
}

// Filter for active zone ids
function findActiveZoneId (sensor) {
    let activeZone = sensor.json.zoneSensors.filter( zone => zone.active === true);
    return activeZone.map(el => el.zoneId)
}

export function SwitchFromZoneButton({sensor, zone, organization, switchSensorMutation}){
    // Get other zone name
    const activeZoneId = findActiveZoneId(sensor)
    const zoneName = organization.zones.reduce((acc, val) => {
        if (activeZoneId.includes(val.id)){
            acc.push(val.name)
        }
        return acc
    }, []).join(', ')

    return <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
        <span style={{textAlign:'center'}}>Currently in Zone:<br/>{zoneName}</span>
        <Button        
            intent="primary"
            onClick={() => switchSensorMutation.mutate({deviceId: sensor.id, action: "switch", zoneId: zone.id, organizationId: organization.id})}
        >
            Switch To This Zone
        </Button>
    </div>
}

SwitchFromZoneButton.propTypes = {
    sensor: PropTypes.object,
    zone: PropTypes.object,
    organization: PropTypes.object,
    switchSensorMutation: PropTypes.object,
}